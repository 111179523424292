<template>
  <div v-loading="loading" element-loading-text="正在导出数据" style="padding: 20px">
    <div>
      <select-area @areaListId="getAreaListId" :clear="clear"/>
      <el-select
          v-model="query.searchType"
          placeholder="校区类型"
          class="handle-select mr10"
      >
        <el-option label="所属校区" :value="1"></el-option>
        <el-option label="上课校区" :value="2"></el-option>
      </el-select>
      <el-select
          clearable
          v-model="query.state"
          placeholder="订单状态"
          class="handle-select mr10"
      >
        <el-option
            v-for="(item, index) in orderState"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictLabel"
        ></el-option>
      </el-select>

      <el-select
          v-model="query.searchCoachType"
          placeholder="教练类型"
          class="handle-select mr10"
      >
        <el-option label="所属教练" :value="1"></el-option>
        <el-option label="服务教练" :value="2"></el-option>
      </el-select>
      <el-input
          v-model="query.coachName"
          placeholder="教练姓名"
          style="width: 160px"
          class="mr10"
      ></el-input>
      <el-input
          v-model="query.studentInfo"
          placeholder="学生姓名,电话,拼音,id"
          style="width: 180px"
          class="mr10"
      ></el-input>
      <el-select
          v-model="query.weekNoCourse"
          placeholder="未出勤时长"
          class="handle-select mr10"
      >
        <el-option label="不限制" :value="0"></el-option>
        <el-option label="一周未出勤" :value="-1"></el-option>
        <el-option label="二周未出勤" :value="-2"></el-option>
        <el-option label="三周未出勤" :value="-3"></el-option>
        <el-option label="一个月未出勤" :value="-4"></el-option>
      </el-select>
      <el-button
          type="primary"
          icon="Search"
          style="margin-left: 10px"
          @click="handleSearch"
      >搜索
      </el-button>
      <el-button type="info" icon="Search" @click="reset"
      >重置搜索条件
      </el-button
      >
      <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
        <template #reference>
          <el-button type="primary" icon="Download"
          >导出
          </el-button
          >
        </template>
      </el-popconfirm>
      <el-popconfirm @confirm="exportUpdateExcel" title="确定要导出吗？">
        <template #reference>
          <el-button type="primary" icon="Download"
          >导出修改记录
          </el-button
          >
        </template>
      </el-popconfirm>
      <el-dialog
          v-model="dialogVisible"
          title="温馨提示"
          width="30%"
          :before-close="handleClose"
      >
        <span>请点击右上角下载中心查看</span>
        <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false"
              >OK</el-button
              >
            </span>
        </template>
      </el-dialog>
    </div>
    <div style="margin: 10px 0 20px 0">
      <el-input
          v-model="query.grade"
          placeholder="届"
          style="width: 100px"
          class="mr10"
      ></el-input>
      <el-select
          clearable
          v-model="query.sourceId"
          placeholder="学生来源"
          class="handle-select mr10"
      >
        <el-option
            v-for="(item, index) in sourceList"
            :key="index"
            :label="item.name"
            :value="item.sourceId"
        ></el-option>
      </el-select>
      <el-select
          clearable
          v-model="query.htState"
          placeholder="合同状态"
          class="handle-select mr10"
      >
        <el-option
            v-for="(item, index) in htStateList"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>
      <el-select
          clearable
          v-model="query.submitType"
          placeholder="订单类型"
          class="handle-select mr10"
      >
        <el-option
            v-for="(item, index) in submitType"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>
      <el-date-picker
          style="width: 260px;margin-right: 10px"
          v-model="v2"
          type="daterange"
          range-separator="至"
          start-placeholder="收款日期"
          end-placeholder="收款日期"
      >
      </el-date-picker>

      <el-date-picker
          style="width: 260px"
          v-model="v1"
          type="daterange"
          range-separator="至"
          start-placeholder="创建日期"
          end-placeholder="创建日期"
      >
      </el-date-picker>
    </div>
    <el-table style="overflow-x: auto !important;"
              v-loading="tableLoading"
              :data="tableData"
              border
              class="table el-table__cell"
              ref="multipleTable"
              :row-style="{ height: 0 + 'px' }"
              :cell-style="{ padding: 0 + 'px' }"
              height="600px"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
    >
      <el-table-column
          fixed
          show-overflow-tooltip="true"
          prop="studentName"
          label="学生姓名"
          width="100"
      >
      </el-table-column>
      <el-table-column fixed label="用户手机号" width="150">
        <template #default="scope">
          <div style="display: flex">
            <div style="width: 150px">
                <span v-if="scope.row.studentPhone != notEncryptId">{{
                    filterTel(scope.row.studentPhone)
                  }}</span>
              <span v-else>{{ scope.row.studentPhone }}</span>
            </div>
            <div>
                <span @click="viewPhone(scope.row.studentPhone)">
                  <svg
                      v-if="scope.row.studentPhone != notEncryptId"
                      t="1644395285994"
                      style="width: 20px; height: 20px"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2629"
                      data-spm-anchor-id="a313x.7781069.0.i16"
                      width="200"
                      height="200"
                  >
                    <path
                        d="M660.8 680.4c-10.1 0-20-5.2-25.5-14.4l-59.4-99.1c-8.4-14.1-3.9-32.3 10.2-40.8 14.1-8.4 32.3-3.8 40.8 10.2l59.4 99.1c8.4 14.1 3.9 32.3-10.2 40.8-4.8 2.9-10.1 4.2-15.3 4.2zM819.1 581.3c-7.6 0-15.2-2.9-21-8.7l-79.2-79.3c-11.6-11.6-11.6-30.4 0-42 11.6-11.6 30.4-11.6 42 0l79.2 79.3c11.6 11.6 11.6 30.4 0 42-5.8 5.9-13.4 8.7-21 8.7zM363.2 680.4c-5.2 0-10.5-1.3-15.3-4.2-14.1-8.4-18.6-26.7-10.2-40.8l59.4-99.1c8.4-14.1 26.7-18.7 40.8-10.2 14.1 8.4 18.6 26.7 10.2 40.8L388.7 666c-5.6 9.3-15.4 14.4-25.5 14.4zM204.9 581.3c-7.6 0-15.2-2.9-21-8.7-11.6-11.6-11.6-30.4 0-42l79.2-79.3c11.6-11.6 30.4-11.6 42 0 11.6 11.6 11.6 30.4 0 42l-79.2 79.3c-5.8 5.9-13.4 8.7-21 8.7z"
                        p-id="2630"
                    ></path>
                    <path
                        d="M521.6 561.5c-82.8 0-204.7-28.4-314.9-163.5-1.3-1.6-2.3-2.8-3-3.6-10.9-10.8-11.8-28.4-1.7-40.3 10.6-12.5 29.4-14.1 41.9-3.5 1.3 1.1 4.4 4.3 8.9 9.8 95.5 117.1 198.9 141.7 268.8 141.7 120.7 0 258.1-124.6 271.6-144.4 9.1-13.2 27.3-17.5 40.9-8.8 13.5 8.6 18.1 25.8 10 39.6-14.1 23.7-168.8 173-322.5 173z"
                        p-id="2631"
                    ></path>
                  </svg>
                  <svg
                      v-else
                      t="1644397194443"
                      style="width: 20px; height: 20px"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2770"
                      width="200"
                      height="200"
                  >
                    <path
                        d="M512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832z m0-576c-129.514667 0-249.461333 83.850667-360.117333 256C262.538667 684.149333 382.485333 768 512 768c129.514667 0 249.461333-83.850667 360.117333-256C761.461333 339.850667 641.514667 256 512 256z m0 405.333333c-83.210667 0-150.666667-66.858667-150.666667-149.333333S428.789333 362.666667 512 362.666667s150.666667 66.858667 150.666667 149.333333S595.210667 661.333333 512 661.333333z m0-64c47.552 0 86.101333-38.208 86.101333-85.333333S559.552 426.666667 512 426.666667c-47.552 0-86.101333 38.208-86.101333 85.333333s38.549333 85.333333 86.101333 85.333333z"
                        p-id="2771"
                    ></path>
                  </svg>
                </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          align="center"
          label="主卡ID"
          prop="orderId"
      >
      </el-table-column>
      <el-table-column
          width="120"
          show-overflow-tooltip="true"
          label="关联订单"
      >
        <template #default="scope">
          <span v-if="scope.row.fatherId!=null">主卡ID：{{ scope.row.fatherId }}</span>
          <span v-if="scope.row.sonId!=null">298ID：{{ scope.row.sonId }}</span>
        </template>
      </el-table-column>

      <el-table-column
          show-overflow-tooltip="true"
          align="center"
          label="主卡"
          width="130"
      >
        <template v-slot="{ row }">
          <el-popover
              :width="row.oldOrders?450:300"
              effect="light"
              trigger="hover"
              placement="right"
          >
            <template #default>
              <div style="display:flex;justify-content:space-around;">
                <div style="margin: 10px;width: 280px;">
                  <div
                      style="background: -webkit-linear-gradient(left,#F5DCB5,#e3c28f);height: 156px;border-radius: 15px;position: relative">
                    <view class="type typecolor" style="background-color: #CDA571;color: white">{{ row.state }}</view>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="row.cardName"
                        placement="top"
                    >
                      <div class="mainCardName">{{ row.cardName }}</div>
                    </el-tooltip>
                    <div style="font-size: 12px;padding:0px 30px;color: #5A4018;margin-top: 10px;">
                      <div style="padding: 4px 0">
                        开始时间:{{ row.startTime == null ? "待使用" : row.startTime }}
                      </div>
                      <div>
                        结束时间:{{ row.endTime == null ? "待使用" : row.endTime }}
                      </div>

                      <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                        <div>
                          <div>{{ row.remaining }}</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 1">剩余课时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 3">剩余小时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 2">剩余课时</div>
                        </div>
                        <div>
                          <div>{{ row.ouse }}</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 1">已用课时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 3">已用小时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 2">已用课时</div>
                        </div>
                        <div>
                          <div>{{ row.buyNumber }}</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 1">总购课时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 3">总购小时</div>
                          <div v-if="row.mainCardRule.subtractCourseWay == 2">总购课时</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="row.sendorder!=null"
                       style="background: -webkit-linear-gradient(left,#C9E3F8,#81B7EB);height: 156px;margin-top: 20px;border-radius: 15px;">
                    <view class="type typecolor" style="background-color: #58a3e9;color: white">
                      {{ row?.sendorder?.state }}
                    </view>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="row?.sendorder?.cardName"
                        placement="top"
                    >
                      <div class="viceCardName">{{ row?.sendorder?.cardName }}</div>
                    </el-tooltip>

                    <div style="font-size: 12px;padding:0px 30px;color: #456087;margin-top: 10px;">
                      <div style="padding: 4px 0">
                        开始时间:{{ row.sendorder.startTime == null ? "待使用" : row.sendorder.startTime }}
                      </div>
                      <div>
                        结束时间:{{ row.sendorder.endTime == null ? "待使用" : row.sendorder.endTime }}
                      </div>
                      <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                        <div>
                          <div>{{ row.sendorder.remaining }}</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">剩余课时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">剩余小时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">剩余课时</div>
                        </div>
                        <div>
                          <div>{{ row.sendorder.suse }}</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">已用课时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">已用小时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">已用课时</div>
                        </div>
                        <div>
                          <div>{{ row.sendNumber }}</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">总购课时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">总购小时</div>
                          <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">总购课时</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="row.oldOrders" style="display:flex;justify-content:center;align-items: center;">
                  <div>
                    <div> 0元卡旧卡信息</div>
                    <div v-if="row.oldOrders">卡片主卡剩余:{{ row.oldOrders.remaining }}</div>
                    <div v-if="row.oldOrders&&row.oldOrders.sendorder">卡片副卡剩余:{{
                        row.oldOrders.sendorder.remaining
                      }}
                    </div>
                    <div v-if="row.old298Orders">卡片298主卡剩余:{{ row.old298Orders.remaining }}</div>
                    <div v-if="row.old298Orders&&row.old298Orders.sendorder">
                      卡片298副卡剩余:{{ row.old298Orders.sendorder.remaining }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #reference>
              <div class="name-wrapper">
                <el-tag
                    size="large"
                    style="width: 100px; text-align: center"
                    show-overflow-tooltip="true"
                    class="sug_info"
                >
                  {{ row.cardName }}
                </el-tag>
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          label="赠卡"
          show-overflow-tooltip="true"
          align="center"
          width="130"
      >
        <template v-slot="{ row }">
            <span style="color: #c0c4cc" v-if="row.sendorder == null"
            >暂无信息</span
            >
          <el-popover
              :width="300"
              v-if="row.sendorder != null"
              effect="light"
              trigger="hover"
              placement="right"
          >
            <template #default>
              <div style="margin: 10px">
                <div
                    style="background: -webkit-linear-gradient(left,#F5DCB5,#e3c28f);height: 156px;border-radius: 15px;position: relative">
                  <view class="type typecolor" style="background-color: #CDA571;color: white">{{ row.state }}</view>
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="row.cardName"
                      placement="top"
                  >
                    <div class="mainCardName">{{ row.cardName }}</div>
                  </el-tooltip>
                  <div style="font-size: 12px;padding:0px 30px;color: #5A4018;margin-top: 10px;">
                    <div style="padding: 4px 0">
                      开始时间:{{ row.startTime == null ? "待使用" : row.startTime }}
                    </div>
                    <div>
                      结束时间:{{ row.endTime == null ? "待使用" : row.endTime }}
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                      <div>
                        <div>{{ row.remaining }}</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 1">剩余课时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 3">剩余小时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 2">剩余课时</div>
                      </div>
                      <div>
                        <div>{{ row.ouse }}</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 1">已用课时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 3">已用小时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 2">已用课时</div>
                      </div>
                      <div>
                        <div>{{ row.buyNumber }}</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 1">总购课时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 3">总购小时</div>
                        <div v-if="row.mainCardRule.subtractCourseWay == 2">总购课时</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="row.sendorder!=null"
                     style="background: -webkit-linear-gradient(left,#C9E3F8,#81B7EB);height: 156px;margin-top: 20px;border-radius: 15px;">
                  <view class="type typecolor" style="background-color: #58a3e9;color: white">
                    {{ row?.sendorder?.state }}
                  </view>
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="row?.sendorder?.cardName"
                      placement="top"
                  >
                    <div class="viceCardName">{{ row?.sendorder?.cardName }}</div>
                  </el-tooltip>
                  <div style="font-size: 12px;padding:0px 30px;color: #456087;margin-top: 10px;">
                    <div style="padding: 4px 0">
                      开始时间:{{ row.sendorder.startTime == null ? "待使用" : row.sendorder.startTime }}
                    </div>
                    <div>
                      结束时间:{{ row.sendorder.endTime == null ? "待使用" : row.sendorder.endTime }}
                    </div>

                    <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
                      <div>
                        <div>{{ row.sendorder.remaining }}</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">剩余课时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">剩余小时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">剩余课时</div>
                      </div>
                      <div>
                        <div>{{ row.sendorder.suse }}</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">已用课时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">已用小时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">已用课时</div>
                      </div>
                      <div>
                        <div>{{ row.sendNumber }}</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 1">总购课时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 3">总购小时</div>
                        <div v-if="row.sendorder.cardAttributeRule.subtractCourseWay == 2">总购课时</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #reference>
              <div class="name-wrapper">
                <el-tag size="large" style="width: 100px; text-align: center" class="sug_info">
                  {{ row.sendorder != null ? row.sendorder?.cardName : "" }}
                </el-tag>
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="120" label="所属区域">
        <template #default="scope">{{ scope.row.submitAreaName }}</template>
      </el-table-column>
      <el-table-column
          width="120"
          show-overflow-tooltip="true"
          label="所属校区"
      >
        <template #default="scope">{{ scope.row.submitCampusName + " - " + scope.row.submitCampusTypeName }}</template>
      </el-table-column>
      <el-table-column
          width="120"
          show-overflow-tooltip="true"
          label="上课校区"
      >
        <template #default="scope"
        >{{ scope.row.campusName != null ? scope.row.campusName + " - " + scope.row.classCampusTypeName : "" }}
        </template>
      </el-table-column>
      <el-table-column width="110" label="收款老师">
        <template #default="scope">{{
            scope.row.coachName != null ? scope.row.coachName : ""
          }}
        </template>
      </el-table-column>
      <el-table-column width="110" label="服务老师">
        <template #default="scope">{{
            scope.row.serveCoachName != null ? scope.row.serveCoachName : ""
          }}
        </template>
      </el-table-column>
      <el-table-column width="110" label="最后出勤时">
        <template #default="scope">{{
            scope.row.beforeCourseTime != null ?
                (scope.row.beforeCourseTime === '1999-09-09 09:09:09' ? '始终未上课' : scope.row.beforeCourseTime)
                : ""
          }}
        </template>
      </el-table-column>

      <el-table-column width="110" prop="money" label="卡片金额">
        <template #default="scope">
          <div>{{ scope.row.money }}元</div>
        </template>
      </el-table-column>
      <el-table-column width="110" prop="money" label="转卡总金额">
        <template #default="scope">
          <div>{{ scope.row.totalPayMoney }}元</div>
        </template>
      </el-table-column>


      <el-table-column width="110" label="收款金额">
        <template #default="scope">
          <div v-if="scope.row.tradingList != null">
            <el-popover
                placement="top"
                title="缴费记录"
                :width="260"
                trigger="hover"
            >
              <el-table :data="scope.row.tradingList">
                <el-table-column prop="name" label="缴费名称">
                  <template #default="scope"
                  >{{ scope.row.name != null ? scope.row.name : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="收款金额">
                  <template #default="scope"
                  >{{ scope.row.money != null ? scope.row.money : "" }}
                  </template>
                </el-table-column>
              </el-table>
              <template #reference>
                <el-button type="text" style="color: #606266"
                > {{ scope.row.proceedsSum }}元
                </el-button
                >
              </template>
            </el-popover>
          </div>
          <div v-else>{{ scope.row.tradingMoney }}元</div>
        </template>
      </el-table-column>
      <!-- 卡片
                      trading收款金额 -->
      <!-- money 卡片金额 -->
      <el-table-column width="140" align="center" label="分成人">
        <template v-slot="{ row }">
          <el-popover
              v-if="row.divideList.length !== 0"
              placement="top"
              :width="260"
              trigger="hover"
          >
            <div v-for="item in row.divideList" :key="item.id">
              <div v-if="item.id" style="margin: 6px 0;display: flex; justify-content:space-between;">
                <div style="width: 110px;">
                  姓名：{{ item.id }}
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 120px;">
                  <span style="text-align:left">金额：{{ item.money }}</span>
                </div>
              </div>
            </div>
            <template #reference>
              <el-tag style="width: 80px">查看信息</el-tag>
            </template>
          </el-popover>
          <span style="color: #c0c4cc" v-else>暂无信息</span>
        </template>
      </el-table-column>

      <el-table-column width="110" align="center" label="收款图片">
        <template v-slot="scope">
          <el-image
              v-if="scope.row.paymentImg"
              class="code"
              :src="
                scope.row.paymentImg.length>=1
                  ? JSON.parse(scope.row.paymentImg)[0]
                  : null
              "
              :preview-src-list="
                 scope.row.paymentImg.length>=1
                  ? JSON.parse(scope.row.paymentImg)
                  : null
              "
          >
            <template #error>
              <div class="code"></div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          prop="contractState"
          align="center"
          label="合同状态"
          width="110"

      >
        <template v-slot="products">
          <span>{{ products.row.htStateName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          width="158"
          label="添加时间"
      ></el-table-column>
      <el-table-column width="110" label="推荐人">
        <template #default="scope">{{
            scope.row.recommendCoachName != null ? scope.row.recommendCoachName : ""
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="updateTime" width="158" label="修改时间">
        <template v-slot="products">
          <span
            :style="products.row.updateTime == null ? 'color: #C0C4CC;' : ''"
            >{{
              products.row.updateTime == null
                ? "暂无编辑过"
                : products.row.updateTime
            }}</span
          >
        </template>
      </el-table-column> -->

      <el-table-column label="操作" width="110" fixed="right" align="center">
        <template #default="scope">
          <el-button @click="detailDialog=true , form = scope.row" size="small" style="padding: 0;margin: 0"
                     type="text">详情
          </el-button>
          <el-popover placement="left" :width="251" trigger="click">
            <el-divider style="margin: 15px 0 0 0">详细记录</el-divider>
            <div class="space-around" style="margin: 10px 0 10px 0">
              <el-button
                  :disabled="
                    scope.row.orderContract === null ||
                    scope.row.orderContract.receiptUrl === null
                      ? true
                      : false
                  "
                  type="text"
                  @click="url(scope.row.orderContract.receiptUrl)"
              >收据查看
              </el-button>
              <el-button
                  type="text"
                  icon="el-icon-s-grid"
                  @click="details(scope.row)"
              >查看详情
              </el-button>
            </div>
            <el-divider style="margin: 15px 0 0 0">合同</el-divider>
            <div class="space-around" style="margin: 10px 0 20px 0">
              <el-popconfirm
                  @confirm="filing(scope.row)"
                  title="确定合同无误吗？"
              >
                <template #reference>
                  <el-button
                      :disabled="scope.row.htState === '3' ? false : true"
                      type="text"
                      style="color: #409eff"
                  >通过
                  </el-button>
                </template>
              </el-popconfirm>
              <el-popconfirm
                  @confirm="rejecteds(scope.row)"
                  title="确定驳回合同吗？"
              >
                <template #reference>
                  <el-button
                      :disabled="
                        scope.row.orderContract === null ||
                        scope.row.htState == '1' ||
                        scope.row.htState == '5'
                          ? true
                          : false
                      "
                      type="text"
                      style="color: red"
                  >拒绝
                  </el-button>
                </template>
              </el-popconfirm>
              <el-button
                  :disabled="
                    scope.row.orderContract === null ||
                    scope.row.htState == '1' ||
                    scope.row.htState == '5'
                      ? true
                      : false
                  "
                  type="text"
                  @click="url(scope.row.orderContract.showUrl)"
              >查看
              </el-button>
              <el-button
                  :disabled="
                    scope.row.orderContract === null ||
                    scope.row.htState == '1' ||
                    scope.row.htState == '5'
                      ? true
                      : false
                  "
                  type="text"
                  @click="url(scope.row.orderContract.durl)"
              >下载
              </el-button>
            </div>
            <el-divider style="margin: 0 0">订单</el-divider>
            <div class="space-around" style="margin: 5px 0 10px 0">
              <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
              >编辑
              </el-button>
              <!-- 第一种方式 自定义指令 v-permission -->
              <!-- v-permission="['order:delete']"-->
              <el-button
                  v-permission="['order:delete']"
                  type="text"
                  style="color: red"
                  @click="handleDelete(scope.$index, scope.row)"
              >删除
              </el-button>
              <el-popover
                  ref="popover"
                  placement="top"
                  :width="175"
                  trigger="focus"
              >
                <div style="text-align: right; margin: 0">
                  <el-tooltip
                      content="提醒用户课时剩余数量不足"
                      placement="top"
                      effect="light"
                  >
                    <el-button
                        type="primary"
                        size="mini"
                        @click="
                          send(scope.$index, scope.row, 'numberDeficiency')
                        "
                    >余额不足
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                      content="提醒用户班卡即将到期"
                      placement="top"
                      effect="light"
                  >
                    <el-button
                        type="primary"
                        size="mini"
                        @click="send(scope.$index, scope.row, 'timeExpire')"
                    >到期提醒
                    </el-button>
                  </el-tooltip>
                </div>
                <template #reference>
                  <el-button type="text" style="color: #e6a23c"
                  >提醒
                  </el-button>
                </template>
              </el-popover>
              <el-tooltip
                  content="重新生成用户的二维码"
                  placement="top"
                  effect="light"
              >
                <el-button
                    type="text"
                    style="color: #67c23a"
                    @click="reLoad(scope.$index, scope.row)"
                >二维码
                </el-button>
              </el-tooltip>
            </div>
            <template #reference>
              <el-button size="small" type="text" style="padding: 0;margin: 0 0 0 14px;">操作</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <el-dialog
        v-model="codeDialog"
        width="430px"
        draggable
        destroy-on-close
        center
    >
      <strong>
        <div class="block">
          <el-image :src="code">
            <template #placeholder>
              <div class="image-slot">加载中<span class="dot">...</span></div>
            </template>
          </el-image>
        </div>
      </strong>
    </el-dialog>
    <!-- 编辑弹出框 -->
    <el-dialog
        title="修改信息"
        draggable
        v-model="editVisible"
        width="1000px"
    >

      <el-dialog
          v-model="innerVisible"
          width="35%"
          title="修改原因"
          append-to-body
      >
        <div style="display:flex;align-items: center;">
          <span>修改原因：</span>
          <el-input placeholder="请输入修改原因" style="width:330px" @change="examine" v-model="explian" show-word-limit
                    type="textarea" :rows="2" maxlength="300"></el-input>
        </div>
        <template #footer>
          <el-button @click="innerVisible=false">取消</el-button>
          <el-button @click="submitUpdate()" type="primary">确定</el-button>
        </template>
      </el-dialog>
      <update-order
          ref="updateOrder"
          :items="form"
          @updateCallback="updateCallback"
      />
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="qx">取 消</el-button>
            <el-button type="primary" @click="innerVisible=true">确 定</el-button>
          </span>
      </template>
    </el-dialog>
    <el-dialog v-model="codeDialog" width="430px" destroy-on-close center>
      <strong>
        <div class="block">
          <el-image :src="code">
            <template #placeholder>
              <div class="image-slot">加载中<span class="dot">...</span></div>
            </template>
          </el-image>
        </div>
      </strong>
    </el-dialog>
    <!--详情-->
    <el-dialog
        @open="opendetail"
        :title="title"
        v-model="selectDetails"
        width="1300px"
        center
        :before-close="handleClose"
    >
      <div style="height: 600px">
        <el-container>
          <el-main>
            <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                stretch="true"
            >
              <el-tab-pane label="主卡上课记录" name="first">
                <template #label>
                    <span class="custom-tabs-label">
                          <span style="margin:0 0 0 20px">主卡上课记录</span>
                      <el-tag v-if="orderLesson" class="ml-2">已有记录课时{{ orderLesson }}</el-tag>
                    </span>
                </template>
                <el-table height="500px"
                          :data="orderCoursesList"
                          border
                          class="table"
                          ref="multipleTable"
                          :row-style="{ height: 10 + 'px' }"
                          :cell-style="{ padding: 5 + 'px' }"
                          header-cell-class-name="table-header"
                >
                  <el-table-column
                      type="index"
                      label="序号"
                      width="50"
                      align="center"
                  ></el-table-column>
                  <el-table-column
                      prop="name"
                      label="姓名"
                      width="80"
                      show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="ctype" label="课时类型" width="90">
                  </el-table-column>
                  <el-table-column prop="region" label="城市" width="90">
                  </el-table-column>
                  <el-table-column prop="flagName" label="类型" width="90">
                  </el-table-column>
                  <el-table-column
                      prop="campusName"
                      label="校区"
                      width="120"
                      show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="coachName" label="提交人" width="90">
                  </el-table-column>
                  <el-table-column label="教练">
                    <template #default="scope">
                      <el-popover
                          v-if="scope.row.teachers.length > 1"
                          placement="right"
                          :width="60"
                          trigger="hover"
                      >
                        <div
                            style="text-align: center"
                            v-for="(item, index) in scope.row.teachers"
                            :key="index"
                        >
                          <el-tag
                              style="
                                width: 80px;
                                text-align: center;
                                margin: 6px 0;
                              "
                          >
                            {{ item.name }}
                          </el-tag>
                        </div>
                        <template #reference>
                          <el-tag
                              type="success"
                              style="width: 80px; text-align: center"
                          >
                            {{ scope.row.teachers[0].name }}
                          </el-tag>
                        </template>
                      </el-popover>
                      <el-tag v-else style="width: 80px; text-align: center">
                        {{ scope.row.teachers[0].name }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="coursedate"
                      label="上课时间"
                      width="170"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="adddate"
                      label="创建时间"
                      width="170"
                  >
                  </el-table-column>
                  <el-table-column label="图片" width="100">
                    <template v-slot="products">
                      <el-image
                          class="code"
                          hide-on-click-modal
                          :src="JSON.parse(products.row.cimg)[0]"
                          :preview-src-list="JSON.parse(products.row.cimg)"
                      >
                        <template #error>
                          <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </template>
                      </el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="副卡上课记录" name="second">
                <template #label>
                    <span class="custom-tabs-label">
                          <span style="margin:0 0 0 20px">副卡上课记录</span>
                     <el-tag v-if="sendorderLesson" class="ml-2">已有记录课时{{ sendorderLesson }}</el-tag>
                    </span>
                </template>
                <div v-if="ordersDetails.sendorder != null">
                  <el-table
                      :data="sendOrderCoursesList"
                      border
                      height="500px"
                      class="table"
                      ref="multipleTable"
                      :row-style="{ height: 10 + 'px' }"
                      :cell-style="{ padding: 5 + 'px' }"
                      header-cell-class-name="table-header"
                  >
                    <el-table-column
                        type="index"
                        label="序号"
                        width="50"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="name"
                        label="姓名"
                        width="80"
                        show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="ctype" label="课时类型" width="90">
                    </el-table-column>
                    <el-table-column prop="region" label="城市" width="90">
                    </el-table-column>
                    <el-table-column prop="flagName" label="类型" width="90">
                    </el-table-column>
                    <el-table-column
                        prop="campusName"
                        label="校区"
                        width="120"
                        show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="coachName"
                        label="提交人"
                        width="90"
                    >
                    </el-table-column>
                    <el-table-column width="100" label="教练">
                      <template #default="scope">
                        <el-popover
                            v-if="scope.row.teachers.length > 1"
                            placement="right"
                            :width="60"
                            trigger="hover"
                        >
                          <div
                              style="text-align: center"
                              v-for="(item, index) in scope.row.teachers"
                              :key="index"
                          >
                            <el-tag
                                style="
                                  width: 80px;
                                  text-align: center;
                                  margin: 6px 0;
                                "
                            >
                              {{ item.name }}
                            </el-tag>
                          </div>
                          <template #reference>
                            <el-tag
                                type="success"
                                style="width: 80px; text-align: center"
                            >
                              {{ scope.row.teachers[0].name }}
                            </el-tag>
                          </template>
                        </el-popover>
                        <el-tag
                            v-else
                            style="width: 80px; text-align: center"
                        >
                          {{ scope.row.teachers[0].name }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="coursedate"
                        label="上课时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adddate"
                        label="创建时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column label="图片">
                      <template v-slot="products">
                        <el-image
                            class="code"
                            hide-on-click-modal
                            :src="JSON.parse(products.row.cimg)[0]"
                            :preview-src-list="JSON.parse(products.row.cimg)"
                        >
                          <template #error>
                            <div class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </template>
                        </el-image>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else-if="ordersDetails.sendorder == null">
                  <span>暂无赠卡</span>
                </div>
              </el-tab-pane>
              <el-tab-pane label="回访记录" name="third">
                <el-table
                    :data="orderFollowList"
                    border
                    class="table"
                    ref="multipleTable"
                    :row-style="{ height: 10 + 'px' }"
                    :cell-style="{ padding: 5 + 'px' }"
                    header-cell-class-name="table-header"
                >
                  <el-table-column
                      type="index"
                      label="序号"
                      width="50"
                      align="center"
                  ></el-table-column>
                  <el-table-column
                      prop="followName"
                      label="姓名"
                      width="80"
                      show-overflow-tooltip="true"
                  >
                  </el-table-column>

                  <el-table-column prop="regionName" label="城市" width="90">
                  </el-table-column>
                  <el-table-column
                      prop="campusName"
                      label="校区"
                      width="120"
                      show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="coachName"
                      label="回访教练"
                      width="90"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="followLabel"
                      label="回访类型"
                      width="90"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="followContent"
                      label="回访内容"
                      show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="followTime"
                      label="回访时间"
                      width="170"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="createTime"
                      label="创建时间"
                      width="170"
                  >
                  </el-table-column>
                  <el-table-column label="图片">
                    <template v-slot="products">
                      <el-image
                          class="code"
                          hide-on-click-modal
                          :src="JSON.parse(products.row.followImg)[0]"
                          :preview-src-list="JSON.parse(products.row.followImg)"
                      >
                        <template #error>
                          <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </template>
                      </el-image>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="修改记录" name="fourth"
              >
                <el-table
                    :data="editPeopleList"
                    border
                    class="table"
                    ref="multipleTable"
                    :row-style="{ height: 10 + 'px' }"
                    :cell-style="{ padding: 5 + 'px' }"
                    header-cell-class-name="table-header"
                >
                  <el-table-column
                      prop="fieldsName"
                      label="修改内容"
                      show-overflow-tooltip="true"
                      width="100"
                  ></el-table-column>
                  <el-table-column
                      prop="userName"
                      label="修改人姓名"
                      show-overflow-tooltip="true"
                      width="100"
                  >
                  </el-table-column>
                  <el-table-column prop="oldContent" label="修改前数据">
                  </el-table-column>
                  <el-table-column prop="newContent" label="修改后数据">
                  </el-table-column>
                  <el-table-column prop="createTime" label="修改时间">
                  </el-table-column>
                  <el-table-column prop="note"
                                   show-overflow-tooltip="true"
                                   width="100"
                                   label="备注">
                  </el-table-column>
                </el-table>
              </el-tab-pane
              >
            </el-tabs>
          </el-main>
        </el-container>
      </div>
    </el-dialog>

    <el-dialog v-model="detailDialog" title="订单信息" width="1200px" draggable>
      <orders-details :item="form"/>
    </el-dialog>
  </div>
</template>

<script>
import {
  page,
  exportExcel, exportUpdateExcel,
  filing,
  rejected,
  del,
  send,
  reQrCode,
  listSource,
} from "@/api/orders";
import {listCampusTree} from "@/api/area.js";
import {selectDictByType} from "@/api/dict";
import {
  selectOrderCourses,
  selectSendOrderCourses,
  selectOrderFollow,
} from "@/api/coursesRecord";
import {selectCardUpdateOrder} from "@/api/card";
import {getorderLesson, getorderEdit} from "@/api/orders.js";
import selectArea from "../../components/SelectArea";
import updateOrder from "./components/UpdateOrder";
import ordersDetails from "./components/OrderDetail";

export default {
  components: {
    selectArea,
    updateOrder,
    ordersDetails
  },
  name: "OrdersManage",
  data() {
    return {
      examineExplian: false,
      explian: '',
      innerVisible: false,
      clear: true,
      editPeopleList: [],
      activeName: "",
      orderLesson: "",
      sendorderLesson: "",
      dialogVisible: false,
      submit: true,
      sysStudentType: localStorage.getItem("studentType"),
      notEncryptId: "", //手机号隐蔽
      codeDialog: false,
      detailDialog: false,
      areaList: [],
      campusList1: [],
      htStateList: [],
      sourceList: [],
      submitType: [],
      orderState: [],
      editVisible: false,
      form: {},
      cardList: [],
      loading: false,
      Upload: false,
      uploadState: {Switch: false, type: ""},
      selectDetails: false,
      orderCoursesList: [], //订单上课记录
      sendOrderCoursesList: [], //赠卡上课记录
      orderFollowList: [], //订单回访
      title: null,
      ordersDetails: null,
      sendOrdersForm: {
        startTime: "",
        endTime: "",
        remaining: "",
        suse: "",
        typeId: "",
        state: null,
        orderId: null,
      },
      queryShow: false,
      query: {
        cardTypeId: "",//卡型
        grade: "",//届数
        searchCoachType: 1,//搜索教练类型1提报教练 2服务教练
        coachName: "",//教练名字
        studentInfo: "",//学生姓名
        state: "",//订单状态
        pageIndex: 1,
        pageSize: 10,
        htState: "",//订单合同状态
        campusString: "",//校区名字
        sourceId: "",//订单来源
        submitType: "",//提报类型
        collectionStartTime: "",//收款时间 范围
        collectionEndTime: "",//收款时间 截止
        areaList: [],//区域列表集合
        searchType: 1,//搜索校区类型 1提报校区 2上课校区
        weekNoCourse: 0 //0不限制 -1一周未出勤 -2两周未出勤 -3三周未出勤 -4一个月未出勤
      },
      tableData: [],
      pageTotal: 0,
      campusID: "",
      campusArray: [],
      tableLoading: false,
      v1: [],
      v2: [],
      SendOrderDialog: false,
      sendOrderRules: {
        typeId: [
          {
            required: true,
            message: "请选择副卡！",
            trigger: "change",
          },
        ],
        remaining: [
          {
            required: true,
            message: "请输入剩余数量！",
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择开始时间！",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: "请选择订单状态",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    examine() {
      // const regex = new RegExp('^[\u4e00-\u9fa5\\d，。！？、￥…]+$');
      // regex.test(this.explian);
      if (this.explian.length > 10) {
        this.examineExplian = true
      } else {
        this.$message.error('请输入不包含单词字符和空格字符原因')
      }

    },
    //打开详情对话框
    opendetail() {
      this.activeName = "first";
    },
    getDays() {
      let oneDay = 24 * 60 * 60 * 1000;
      let endTime = new Date(Date.now());
      endTime = this.formatterDate(endTime, "yyyy-MM-dd");
      let startTime = new Date(Date.now() - 6 * oneDay);
      startTime = this.formatterDate(startTime, "yyyy-MM-dd");
      this.v1 = [startTime, endTime];
      this.query.startDate = startTime;
      this.query.endDate = endTime;
    },
    formatterDate(date, fmt) {
      let nowDate = {
        yyyy: date.getFullYear(), // 年
        MM: date.getMonth() + 1, // 月份
        dd: date.getDate(), //日
        hh: date.getHours(),
        mm: date.getMinutes(),
        ss: date.getSeconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in nowDate) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                  ? nowDate[k]
                  : ("00" + nowDate[k]).substr(("" + nowDate[k]).length)
          );
        }
      }
      return fmt;
    },
    //确定更新学员信息
    submitUpdate() {
      if (this.examineExplian) {
        this.$refs.updateOrder.update(this.explian);
        setTimeout(() => {
          this.editVisible = false
          this.innerVisible = false
          this.getData();
        }, 3000);
      } else {
        this.$message.error('请输入详细修改原因,十个字以上哦!')
      }
    },
    updateCallback() {
      this.editVisible = false;
      this.getData();
    },
    changeStudentType(val) {
      this.getAreaList(val);
      this.selectCardUpdateOrder(val);
    },
    //获取区域列表
    getAreaList(typeId) {
      listCampusTree(typeId).then((val) => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.areaList = val.data;
        }
      });
    },
    selectCardUpdateOrder(typeId) {
      selectCardUpdateOrder(typeId).then((val) => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.cardList = val.data;
        }
      });
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach((items) => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    getAreaListId(val) {
      this.query.areaList = val;
    },
    // 获取数据
    getData() {
      console.log(this.query)
      // 表格数据
      this.tableLoading = true;
      if (this.campusID !== "") {
        this.campusArray.push(this.campusID);
        this.query.campusString =
            this.campusArray[this.campusArray.length - 1].toString();
      }
      page(this.query).then((res) => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            if (item.tradingList) {
              let sum = 0
              item.tradingList.forEach(item => {
                sum += item.money
              })
              item.proceedsSum = sum
            }
          })
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
          .catch(() => {
          });
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    //手机号隐蔽
    viewPhone(val) {
      if (val == this.notEncryptId) {
        this.notEncryptId = "";
      } else {
        this.notEncryptId = val;
      }
    },
    //详情
    details(val) {
      let _this = this;
      _this.sendorderLesson = ''
      _this.orderLesson = ''
      if (val.sendorder != null) {
        getorderLesson(val.sendorder.sid, 2).then((res) => {
          // console.log('副卡记录课时',res.data);
          if (res.code == 200) {
            _this.sendorderLesson = res.data;
            // console.log( _this.sendorderLesson);
          } else {
            this.$message.error('获取已记录课时失败')
          }
        });
      }

      getorderEdit(val.orderId).then(res => {
        if (res.code == 200) {
          this.editPeopleList = res.data
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
      getorderLesson(val.orderId, 1).then((res) => {
        // console.log('主卡记录课时',res.data);
        if (res.code == 200) {
          this.orderLesson = res.data;
        }
      });
      this.selectDetails = true;
      this.title = val.studentName + "信息";
      this.ordersDetails = val;
      //主卡上课记录
      selectOrderCourses(val.orderId).then((res) => {
        if (res.code === 200) {
          this.orderCoursesList = res.data;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }); //回访上课记录
      selectOrderFollow(val.orderId).then((res) => {
        if (res.code === 200) {
          this.orderFollowList = res.data;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }); //赠卡上课记录
      selectSendOrderCourses(val.orderId).then((res) => {
        if (res.code === 200) {
          this.sendOrderCoursesList = res.data;
        } else {
          this.$message.error("获取表格数据失败");
        }
      });
    },
    filterTel(val) {
      //加密手机号
      if (val) {
        return val.substring(3, 0) + "****" + val.substring(7);
      }
    }, //赠卡
    imgView(img) {
      this.codeDialog = true;
      this.code = img;
    },
    // 触发搜索按钮
    handleSearch() {
      // console.log(this.v1);
      // console.log(this.v2);
      if (this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }
      if (this.v2.length >= 2) {
        this.query.collectionStartTime = this.dateFormat(this.v2[0]);
        this.query.collectionEndTime = this.dateFormat(this.v2[1]);
      }
      this.query.pageIndex = 1;
      this.getData();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 编辑操作
    handleEdit(index, row) {
      this.form = row;
      this.editVisible = true;
    },
    choiceTeacher(val) {
      this.form.teacherId = val;
    },
    selectSendOrder(val, id) {
      if (val != null) {
        this.sendOrdersForm = val;
      } else {
        let sendOrdersForm = {
          startTime: "",
          endTime: "",
          remaining: "",
          suse: "",
          typeId: "",
          state: null,
          orderId: null,
        };
        this.sendOrdersForm = sendOrdersForm;
      }
      this.sendOrdersForm.orderId = id;
      // console.log(val);
      this.SendOrderDialog = true;
    },
    url(url) {
      window.open(url);
    },
    /**
     * 通过合同
     */
    filing(row) {
      filing(row.orderContract.id).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("归档成功");
          this.getData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * 驳回合同
     */
    rejecteds(row) {
      rejected(row.orderContract.id).then((res) => {
        if (res.code == 200) {
          this.$message.success("合同已驳回");
          this.getData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除: " + row.studentName + " 吗?", "提示", {
        type: "warning",
      })
          .then(() => {
            del(row.orderId).then((res) => {
              if (res.code == 200) {
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
                this.getData();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
          });
    },
    exportExcel() {
      this.dialogVisible = true;
      if (this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }
      exportExcel(this.query)
    },

    exportUpdateExcel() {
      if (this.v1.length >= 2) {
        this.dialogVisible = true;
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
        exportUpdateExcel(this.query);
      } else {
        this.$message.error("请选择创建时间！");
      }
    },
    handleRemove() {
      this.fileList = [];
    },
    //短信提醒
    send(index, row, type) {
      var form = {
        phone: row.studentPhone,
        name: row.studentName,
        date: row.createTime,
        date2: row.endTime,
        number: row.remaining,
        card: row.cardName,
        type: type,
      };
      send(form).then((res) => {
        if (res.code == 200) {
          this.$message.success("提醒成功");
        } else {
          this.$message.error("提醒失败");
        }
      });
    },
    // 重新生成二维码
    reLoad(index, row) {
      this.$confirm(
          "确定为: " + row.studentName + "重新生成二维码吗？之前的将会失效",
          "提示",
          {
            type: "warning",
          }
      )
          .then(() => {
            reQrCode(row.oid).then((res) => {
              if (res.code == 200) {
                if (res.success) {
                  this.$message.success("重新生成成功，之前二维码失效");
                  this.getData();
                } else {
                  this.$message.error(res.message);
                }
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message.info("取消重新生成二维码");
          });
    },
    // 重置
    reset() {
      this.clear = !this.clear
      this.campusArray = [];
      this.query.regionId = "";
      this.query.campusId = "";
      this.query.cardTypeId = "";
      this.query.coachName = "";
      this.query.studentInfo = "";
      this.query.state = "";
      this.query.htState = "";
      this.query.grade = "";
      this.query.sourceId = "";
      this.query.submitType = "";
      // 日期
      this.v1 = [];
      this.query.startDate = "";
      this.query.status = "";
      this.query.endDate = "";
      this.query.pageIndex = 1;
      this.query.campusString = "";
      this.campusList = [];
      this.campusID = "";
      this.v2 = [];
      this.query.collectionStartTime = "";
      this.query.collectionEndTime = "";
      this.query.areaList = []
      this.getData()

    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    dateFormatC(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var hh = myDate.getHours(); //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      const fname =
          "订单表" +
          year +
          "年" +
          month +
          "月" +
          day +
          "日" +
          hh +
          "时" +
          mm +
          "分" +
          ss +
          "秒";
      return fname;
    },
  },
  mounted() {


  },
  created() {
    let item = this.$route;
    if (item.params?.id != null) {
      this.query.studentInfo = item.params?.id;
    }
    this.getData();
    this.getAreaList();
    selectDictByType("contract").then((res) => {
      if (res.code == 200) {
        this.htStateList = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
    listSource().then((res) => {
      if (res.code == 200) {
        this.sourceList = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
    //订单类型
    selectDictByType("submitType").then((res) => {
      if (res.code == 200) {
        this.submitType = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
    //订单状态
    selectDictByType("orderState").then((res) => {
      if (res.code == 200) {
        this.orderState = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
  },
  watch: {
    $route(item) {
      if (item.params?.id != null) {
        this.query.studentInfo = item.params?.id;
        this.getData();
      }
    }
  }
};
</script>
<style type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>
<style scoped>
.ml-2 {
  margin-left: 10px;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.sug_info {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.handle-box {
  margin-bottom: 20px;
}

.card {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {

  width: 100%;
  font-size: 14px;
}

.mr10 {
  margin-right: 10px;
}

.sendOrder {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: #efefef;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}

.mainCardName {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #bb9c6e, #000000);
  font-style: italic;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px 5px 16px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viceCardName {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #81B7EB, #000000);
  font-style: italic;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type {
  line-height: 26px;
  font-style: italic;
  border-radius: 0px 15px 0px 15px;
  font-weight: bold;
  height: 26px;
  width: 66px;
  font-size: 12px;
  text-align: center;
  float: right;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}

.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>
